import React, { useState } from 'react';
import Loader from '../common/loader';
import CreateNewElement from './createNewElement';
import EditContainer from './editContainer';
import EditDate from './editDate';
import EditImage from './editImage';
import DeleteElement from '../common/deleteElement';

const LeftBar = ({newElementDetails, setNewElementDetails, newElementOpen, setNewElementOpen, onElementOpen,  elements, onCreateElement, createLoader, createMessage, setElements}) => {
    const [loading, setLoading ] = useState(false);
    const [editComponent, setEditComponent] = useState(null); // State for tracking which component to edit
    const [deleteConfirmation, setDeleteConfirmation] = useState(null); // State for tracking which element to delete

    const handleEditOpen = (index, type) => {
        setEditComponent({index, type});
    };

    const handleEditClose = () => {
        setEditComponent(null);
    };

    const handleDeleteConfirmation = (index, type) => {
        setDeleteConfirmation({index, type});
    };

    const handleDeleteCancel = () => {
        setDeleteConfirmation(null);
    };

    const handleConfirmDelete = () => {
        const { index, type } = deleteConfirmation;
        const updatedElements = [...elements];
        
        if (type === "Date") {
            updatedElements[index].date = undefined;
            updatedElements[index].day = undefined;
            updatedElements[index].month = undefined;
            updatedElements[index].year = undefined;

        } else if (type === "Container") {
            updatedElements[index].time = undefined;
            updatedElements[index].title = undefined;
            updatedElements[index].description = undefined;
        } else if (type === "Image") {
            updatedElements[index].elementImage = undefined;
        }
        
        setElements(updatedElements.filter((element) => {
            const { date, title, time, description, elementImage } = element;
            return date !== undefined || title !== undefined || time !== undefined || description !== undefined || elementImage !== undefined;
        }));
        
        setDeleteConfirmation(null);
    };

    return (
        <div className={`flex flex-col border border-[#EBECF0] w-[20%] h-[95%] items-center rounded-[1.15rem] bg-white my-4 ml-6 lg:flex`}>
             <p className='font-bold p-2 bg-[#1E78C8] w-[70%] my-5 text-sm mx-2 text-white rounded-[10px] cursor-pointer border-2 border-[#1E78C8] hover:bg-white hover:text-[#1E78C8] ease-in-out duration-300' onClick={onElementOpen}>
                 {!loading ? <p className='flex '><span className="material-symbols-outlined transition-all duration-300">add</span><p className='mt-0.5'>Create New Element</p></p> : <Loader/>}
             </p>
             {newElementOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                      <CreateNewElement onClose={onElementOpen} setNewElementDetails={setNewElementDetails} newElementDetails={newElementDetails} onCreateElement={onCreateElement} createLoader={createLoader} createMessage={createMessage}/>
                </div>
             )}
            <div className='w-[80%] relative overflow-auto'>
                {elements?.length > 0 && elements.map((element, index) => (
                    <>
                        {(element?.date || (element?.title && element?.time && element?.description) || element?.elementImage) && (
                            <div key={index} className='flex w-full'>
                                <p>Element {index+1}</p>
                            </div>
                        )}
                        {element?.date && (
                            <div key={`${index}-date`} className='flex w-full justify-between my-3 bg-gray-200 rounded-[10px] p-2'>
                                <p className='mt-1'>Date</p>
                                <div>
                                    <button className='border-2 border-gray-300 bg-gray-600 rounded-[10px] text-white px-2 mr-1' onClick={() => handleEditOpen(index, "Date")}><span className="material-symbols-outlined text-[18px] mt-1">edit</span></button>
                                    <button className='border-2 border-red-300 bg-red-600 rounded-[10px] text-white px-2 mr-1' onClick={() => handleDeleteConfirmation(index, "Date")}><span className="material-symbols-outlined text-[18px] mt-1">delete</span></button>
                                </div>
                            </div>
                        )}
                        {(element?.title && element?.time && element?.description) && (
                            <div key={`${index}-container`} className='flex w-full justify-between my-3 bg-gray-200 rounded-[10px] p-2'>
                                <p className='mt-1'>Container</p>
                                <div>
                                    <button className='border-2 border-gray-300 bg-gray-600 rounded-[10px] text-white px-2 mr-1' onClick={() => handleEditOpen(index, "Container")}><span className="material-symbols-outlined text-[18px] mt-1">edit</span></button>
                                    <button className='border-2 border-red-300 bg-red-600 rounded-[10px] text-white px-2 mr-1' onClick={() => handleDeleteConfirmation(index, "Container")}><span className="material-symbols-outlined text-[18px] mt-1">delete</span></button>
                                </div>
                            </div>
                        )}
                        {element?.elementImage && (
                            <div key={`${index}-image`} className='flex w-full justify-between my-3 bg-gray-200 rounded-[10px] p-2'>
                                <p className='mt-1'>Image</p>
                                <div>
                                    <button className='border-2 border-gray-300 bg-gray-600 rounded-[10px] text-white px-2 mr-1' onClick={() => handleEditOpen(index, "Image")}><span className="material-symbols-outlined text-[18px] mt-1">edit</span></button>
                                    <button className='border-2 border-red-300 bg-red-600 rounded-[10px] text-white px-2 mr-1' onClick={() => handleDeleteConfirmation(index, "Image")}><span className="material-symbols-outlined text-[18px] mt-1">delete</span></button>
                                </div>
                            </div>
                        )}
                        {deleteConfirmation && deleteConfirmation.index === index && (
                            <div key={`${index}-delete`} className="fixed inset-0 bg-black bg-opacity-50 z-40">
                                <DeleteElement onClose={handleDeleteCancel} onConfirmDelete={handleConfirmDelete} type={deleteConfirmation.type} index={index} />
                            </div>
                        )}
                        {editComponent && editComponent.index === index && (
                            <div key={`${index}-edit`} className="fixed inset-0 bg-black bg-opacity-50 z-40">
                                {editComponent.type === "Date" && <EditDate onClose={handleEditClose} date={element?.date} elements={elements} setElements={setElements} index={index}/>}
                                {editComponent.type === "Container" && <EditContainer onClose={handleEditClose} title={element?.title} time={element?.time} description={element?.description} elements={elements} setElements={setElements} index={index}/>}
                                {editComponent.type === "Image" && <EditImage onClose={handleEditClose} image={element?.elementImage} elements={elements} setElements={setElements} index={index}/>}
                            </div>
                        )}
                    </>
                ))}
            </div>
        </div>
    );
}

export default LeftBar;
