import React, { useEffect, useState } from 'react';
import { uploadFile, MEDIA_URL } from '../api';

const EditImage = ({onClose, image, setElements, elements, index}) => {

    const [elementUrl, setElementUrl] = useState(image);
    const [selectedFile, setSelectedFile] = useState(image);
    const updatedElements = [...elements];
    const [uploading, setUploading] = useState()

    const onSave = () =>{
      setElements(updatedElements);
      onClose()
    }

    const handleChange = (event) => {
      const file = event.target.files[0];
      if (file) {
          setSelectedFile(file);

          

          const reader = new FileReader();
          reader.onload = () => {
              setElementUrl(reader.result);
          };
          reader.readAsDataURL(file);
      }
    
    };

    useEffect(()=>{
      const uploadImage = async() =>{

        const parts = elementUrl.split("/");
        const imageNameWithExtension = parts[parts.length - 1];
        const imageName = imageNameWithExtension.split(".")[0];
        const renamedBlob = new Blob([selectedFile], { type: selectedFile.type });
        const renamedFile = new File([renamedBlob], `${imageName}.${selectedFile.name.split('.').pop()}`, { type: selectedFile.type });
        setUploading("SAVING IMAGE 30%.....")
        const { response } = await uploadFile(renamedFile);
        console.log("RESPONSE", response)
        const imageLink = `${MEDIA_URL}`+'media/'+renamedFile.name
        updatedElements[index].elementImage = imageLink
        setUploading("SAVED IMAGE 100%.....")
        setTimeout(() => {
          setUploading('')
        }, 2000);
      }
      if(selectedFile instanceof File){
          setUploading("SAVING IMAGE 10%.....")
          uploadImage();
      }
    }, [selectedFile])

    
    console.log("IMAGE", image)
    return (
        <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 flex flex-col bg-gray-100 w-[500px] h-[250px] rounded-[10px] shadow-md items-center overflow-auto'>           
            <div className='mx-8 my-4 w-[80%] text-left'>
              <p className='text-base font-bold'>Edit Image</p>
            </div>
            <div className='mx-8 w-[80%] mt-4 text-center text-base absolute text-blue-600 font-bold'><p>{uploading}</p></div>
            <div className='mx-8 my-2 w-[80%]'>
              
              <div className='relative flex items-center justify-center w-full h-[100px] border border-dashed border-gray-400 p-2'>
                  <img src={elementUrl}
                      alt="add-image"
                      className='object-cover w-content h-full cursor-pointer' 
                      onClick={() => document.getElementById('ImgInput').click()}
                      />
                 
                  <input
                      type="file"
                      id="ImgInput"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={handleChange}
                  />
                  {selectedFile&&<>
                    <button className="absolute top-0 right-0 mt-2 mr-2 rounded-full bg-white text-gray-400 px-2 hover:text-white hover:bg-[#1E78C8] ease-in-out duration-300 font-bold" onClick={() => document.getElementById('ImgInput').click()}><span className="material-symbols-outlined text-[16px] mt-2"> edit </span></button>
                  
                  </>}
              </div>    
            </div>
            <div className='flex w-[80%] my-4 mb-6 h-10 text-sm'>
                <button className='font-bold w-[50%] mx-2 rounded-[10px] bg-gray-400 text-white cursor-pointer border-2 border-gray-400 hover:bg-white hover:text-gray-600 ease-in-out duration-300' onClick={onClose}>Cancel</button>
                <button className='font-bold w-[50%] bg-[#1E78C8] mx-2 text-white rounded-[10px] cursor-pointer border-2 border-[#1E78C8] hover:bg-white hover:text-[#1E78C8] ease-in-out duration-300' onClick={onSave}>Save</button>
            </div> 
        </div>
    );
}

export default EditImage;