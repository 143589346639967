import React, { useState } from 'react';

const DeleteElement = ({onClose, image, setElements, elements, index, type, onConfirmDelete}) => {

    const [elementUrl, setElementUrl] = useState(image);
    const [selectedFile, setSelectedFile] = useState(image);
    //const updatedElements = [...elements];

    const onSave = () =>{
      //setElements(updatedElements);
      onClose()
    }

    const handleChange = (event) => {
      const file = event.target.files[0];
      if (file) {
          setSelectedFile(file);
          setElements({...elements, elementImage: file})
          const reader = new FileReader();
          reader.onload = () => {
              setElementUrl(reader.result);
          };
          reader.readAsDataURL(file);
      }
    
    };

    const onDelete = () => {
      setSelectedFile(undefined)
      setElementUrl("./add.png");
    };
    const onCancel = () => {
        setElements(undefined);
        onClose()
    }
    console.log("INDEX", index)
    return (
        <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 flex flex-col bg-gray-100 w-[500px] h-[250px] rounded-[10px] shadow-md items-center overflow-auto'>           
             <div className='m-4 mt-6 w-[80%]'>
          <p className='text-base font-bold'>Delete Confirmation</p>
        </div>
        <div className='mx-4 my-8 text-sm'>
          <p className=''>Are you sure you want to delete this {type} from this Element</p>
        </div>
            <div className='flex w-[80%] my-4 mb-6 h-10 text-sm'>
                <button className='font-bold w-[50%] mx-2 rounded-[10px] bg-gray-400 text-white cursor-pointer border-2 border-gray-400 hover:bg-white hover:text-gray-600 ease-in-out duration-300' onClick={onClose}>Cancel</button>
                <button className='font-bold w-[50%] bg-white mx-2 text-red-600 rounded-[10px] cursor-pointer border-2 border-red-600 hover:bg-red-600 hover:text-white ease-in-out duration-300' onClick={(e)=> onConfirmDelete({type: type, index: index})}>Delete</button>
            </div> 
        </div>
    );
}

export default DeleteElement;