import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../common/loader';


const Header = ({setFile, toggleSidebar, pdfUrl, loaders, settings, setSettings, timeLineUrl, handleConvertToPdf, handleConvertToImage}) => {

    const [isOpen, setOpen] = useState()
    const toggleDropdown = () =>{
      setOpen(!isOpen);
    }

    const exportUrl = () => {
      // Get the current URL of the site
      const currentUrl = window.location.href;

      const baseUrl = currentUrl.replace(/\/edit\/[\w-]+/, '');
      // Construct the export URL
      const exportUrl = `${baseUrl}/viewPage/${timeLineUrl}`;
      
      // Construct the iframe code
      const iframeCode = `<iframe src="${exportUrl}" width="600" height="400"></iframe>`;
      
      // Create a temporary textarea element to copy the iframe code
      const textarea = document.createElement('textarea');
      textarea.value = iframeCode;
      document.body.appendChild(textarea);
      
      // Select and copy the iframe code
      textarea.select();
      document.execCommand('copy');
      
      // Remove the temporary textarea
      document.body.removeChild(textarea);
      
      // Display alert message
      alert("TIMELINE iframe copied to clipboard!");
    }
    
    const handleFileImport = () => {
        document.getElementById('fileInput').click();
      };
    
    const handleFileChange = (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile && selectedFile.type === 'application/pdf') {
     
        const fileUrl = URL.createObjectURL(selectedFile);
        setFile(selectedFile)
      } else {
        console.error('Invalid file format. Please select a PDF file.');
      }
    };

    return (
        <div className='flex justify-end relative'>
            <a href='/dashboard' className='absolute flex left-2 top-3'>
               <img src="/logo.png" alt="Logo" className="h-10 w-auto mx-4" />
               <p className='mt-2 font-bold text-[16px]'>Timeline</p>
            </a>
            {/* <p className={`mt-4 -mb-3 font-bold text-[12px] text-left absolute lg:left-40 left-8 top-9 lg:top-10`}>Note* Changes will reflect on preview after save</p> */}
            <div className='flex justify-between p-3 text-[#1E78C8] hidden lg:flex'>
               {/*  <p className='flex cursor-pointer font-bold p-2 bg-[#1E78C8] border-2 border-[#1E78C8] ease-in-out duration-300 text-sm mx-1 text-white rounded-[0.5rem]' onClick={handleFileImport}> IMPORT</p> 
                <input
                  type="file"
                  id="fileInput"
                  accept=".pdf"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                /> */}
                <p className='flex cursor-pointer font-bold p-2 bg-[#1E78C8] border-2 border-[#1E78C8] ease-in-out duration-300 text-sm mx-1 text-white rounded-[0.5rem]' onClick={exportUrl}> EXPORT IFRAME</p>
                <p className='flex cursor-pointer font-bold p-2 bg-[#1E78C8] border-2 border-[#1E78C8] ease-in-out duration-300 text-sm mx-1 text-white rounded-[0.5rem]' onClick={(e)=>handleConvertToPdf('pdf')}> {loaders.pdfDownload? <Loader/> : "EXPORT PDF"}</p>
                <p className='flex cursor-pointer font-bold p-2 bg-[#1E78C8] border-2 border-[#1E78C8] ease-in-out duration-300 text-sm mx-1 text-white rounded-[0.5rem]' onClick={(e)=>handleConvertToPdf('image')}> {loaders.imageDownload? <Loader/> : "EXPORT IMAGE"}</p>
                <p className='flex cursor-pointer font-bold p-2 bg-[#1E78C8] border-2 border-[#1E78C8] ease-in-out duration-300 text-sm mx-1 text-white rounded-[0.5rem]' 
                        onClick={(e)=>{
                          if(settings?.published){
                            window.open(`/view/${timeLineUrl}`, '_blank')
                          }
                        }}> {settings?.published ? "PUBLISHED": "PUBLISH"}</p>
            </div>
            <div className="relative inline-block lg:hidden text-left mr-3 mt-4 z-50">
                <button
                  type="button"
                  className="inline-flex justify-center items-center w-full px-4 py-2 text-sm font-medium bg-[#1E78C8] border-2 border-[#1E78C8] ease-in-out duration-300 text-sm mx-2 text-white rounded-[0.5rem]"
                  onClick={toggleDropdown}
                  onBlur={toggleDropdown}
                >
                  Options <span class='material-symbols-outlined text-sm'>expand_more</span>
                </button>
                
              
                {isOpen ? (
                  <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1 " role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                   
                     {/*  <button
                        type="button"
                        className="block px-2 py-2 text-sm text-gray-700 "
                        role="menuitem"
                        onClick={handleFileImport}
                      >
                       IMPORT
                         <input
                          type="file"
                          id="fileInput"
                          accept=".pdf"
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                        />
                      </button> */}
                      <button
                        type="button"
                        className="block px-2 py-2 text-sm text-gray-700 "
                        role="menuitem"
                        onClick={exportUrl}
                      >
                       EXPORT IFRAME
                      </button>
                      <button
                        type="button"
                        className="block px-2 py-2 text-sm text-gray-700 "
                        role="menuitem"
                        onClick={(e)=>handleConvertToPdf()}
                
                      >
                       {loaders.pdfDownload? <Loader/> : "EXPORT PDF"}
                      </button>
                      <button
                        type="button"
                        className="block px-2 py-2 text-sm text-gray-700 "
                        role="menuitem"
                        onClick={(e)=>handleConvertToImage()}
                      >
                       {loaders.imageDownload? <Loader/> : "EXPORT IMAGE"}
                      </button>
                      <button
                        type="button"
                        className="block px-2 py-2 text-sm text-gray-700 "
                        role="menuitem"
                        onClick={(e)=>{
                          if(settings?.published){
                            window.open(`/view/${timeLineUrl}`, '_blank')
                          }
                        }}
                      >
                       {settings?.published? "PUBLISHED": "PUBLISH"}
                      </button>
                    
                      
                    </div>
                  </div>
                ):(
                  <>
                  </>
                )}
                
              </div>
              <div className='flex mr-3 mt-4 bg-[#1E78C8] text-white px-4 items-center rounded-[0.5rem] cursor-pointer lg:hidden' onClick={toggleSidebar}>
                    <span class="material-symbols-outlined"> menu </span>
                </div>
        </div>
    );
}

export default Header;