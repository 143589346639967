import React, { useState } from 'react';

const EditDate = ({onClose, date, setElements, elements, index}) => {

    const updatedElements = [...elements];

    const onSave = () =>{
      setElements(updatedElements);
      onClose()
    }


    return (
        <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 flex flex-col bg-gray-100 w-[500px] h-[250px] rounded-[10px] shadow-md items-center overflow-auto'>           
            <div className='mx-8 my-4 w-[80%] text-left'>
              <p className='text-base font-bold'>Edit Date</p>
            </div>
            
            <div className='mx-8 my-2 w-[80%] border'>
              <div className='flex justify-between'>
                <label htmlFor="date">Date</label>
                <div>
                
                </div>
              </div>
              <input type='date' className="border-2 border-gray-200 w-full p-2 rounded-[10px] " value={date} name="date" 
                    onChange={(e)=>{
                      const dateString = e.target.value; 
                      const dateObject = new Date(dateString);
                                          
                      const day = dateObject.getDate(); 
                      const month = dateObject.getMonth() + 1; 
                      const year = dateObject.getFullYear(); 
                      
            
                      
                      updatedElements[index].date = dateString;
                      updatedElements[index].day = day;
                      updatedElements[index].month = month;
                      updatedElements[index].year = year;
                              
                      
                      
                    }}/>
            </div>
            
            <div className='flex w-[80%] my-4 mb-6 h-10 text-sm'>
                <button className='font-bold w-[50%] mx-2 rounded-[10px] bg-gray-400 text-white cursor-pointer border-2 border-gray-400 hover:bg-white hover:text-gray-600 ease-in-out duration-300' onClick={onClose}>Cancel</button>
                <button className='font-bold w-[50%] bg-[#1E78C8] mx-2 text-white rounded-[10px] cursor-pointer border-2 border-[#1E78C8] hover:bg-white hover:text-[#1E78C8] ease-in-out duration-300' onClick={onSave}>Save</button>
            </div> 
        </div>
    );
}

export default EditDate;