import React, { useState } from 'react';
import Loader from '../common/loader';

const CreateNewElement = ({onClose, onCreateElement, createLoader, createMessage, newElementDetails, setNewElementDetails}) => {

    const [elementUrl, setElementUrl] = useState('./add.png');
    const [selectedFile, setSelectedFile] = useState(newElementDetails?.elementImage);

    const handleChange = (event) => {
      const file = event.target.files[0];
      if (file) {
          setSelectedFile(file);
          setNewElementDetails({...newElementDetails, elementImage: file})
          const reader = new FileReader();
          reader.onload = () => {
              setElementUrl(reader.result);
          };
          reader.readAsDataURL(file);
      }
    
    };

    const onDelete = () => {
      setSelectedFile(undefined)
      setElementUrl("./add.png");
    };
    const onCancel = () => {
        setNewElementDetails(undefined);
        onClose()
    }
    console.log("LOADER", createLoader)
    return (
        <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 flex flex-col bg-gray-100 w-[500px] h-[700px] rounded-[10px] shadow-md items-center overflow-auto'>           
            <div className='mx-8 my-4 w-[80%] text-left'>
              <p className='text-base font-bold'>Create Element</p>
            </div>
            
            <div className='flex flex-col mx-2 items-center '>
            <div className='mx-8 w-[80%] text-center text-base absolute text-blue-600 font-bold'><p>{createMessage}</p></div>
            
            <div className='mx-8 my-2 w-[80%]'>
              <div className='flex justify-between'>
                <label htmlFor="date">Date</label>
                <div>
                
                </div>
              </div>
              <input type='date' className="border-2 border-gray-200 w-full p-2 rounded-[10px] " value={newElementDetails?.date} name="date" 
                    onChange={(e)=>{
                      const dateString = e.target.value; 
                      const dateObject = new Date(dateString);
                                          
                      const day = dateObject.getDate(); 
                      const month = dateObject.getMonth() + 1; 
                      const year = dateObject.getFullYear(); 
                      
                      setNewElementDetails({...newElementDetails, date: e.target.value, day: day, month: month, year: year})
                    }}/>
            </div>
            
            <div className='mx-8 my-2 w-[80%]'>
              <div className='flex justify-between'>
                <label htmlFor="date">Time <span className='text-[#8392AB]'>(e.g 2016-2019)</span></label>
                <div>
                
                </div>
              </div>
              <input className="border-2 border-gray-200 w-full p-2 rounded-[10px] " value={newElementDetails?.time} name="time" onChange={(e)=>setNewElementDetails({...newElementDetails, time: e.target.value})}/>
            </div>
            
            <div className='mx-8 my-2 w-[80%]'>
              <div className='flex justify-between'>
                <label htmlFor="title">Title <span className='text-[#8392AB]'>(max 200 characters)</span></label>
                <div>
                <input type="color" name="title-color" id="" className='bg-transparent rounded-md w-6' 
                
                value={undefined} onChange={(e)=>setNewElementDetails({...newElementDetails, color: e.target.value})}/>
                <input type="color" name="title-color" id="" className='bg-transparent rounded-md w-6' 
                
                value={undefined} onChange={(e)=>setNewElementDetails({...newElementDetails, color: e.target.value})}/>
                </div>
              </div>
              <input className="border-2 border-gray-200 w-full p-2 rounded-[10px] " value={newElementDetails?.title} name="title" onChange={(e)=>setNewElementDetails({...newElementDetails, title: e.target.value})}/>
            </div>
           
            <div className='mx-8 my-2 w-[80%] text-left'>
              <label htmlFor="description">Description <span className='text-[#8392AB]'>(max 500 characters)</span></label>
              <textarea className="border-2 border-gray-200 w-full px-2 py-1 rounded-[10px]" name="description" rows={5} value={newElementDetails?.description} onChange={(e)=>setNewElementDetails({...newElementDetails, description: e.target.value})}/>
            </div>
            <div className='mx-8 my-2 w-[80%]'>
              <div className='relative flex items-center justify-center w-full h-[100px] border border-dashed border-gray-400 p-2'>
                  <img src={elementUrl}
                      alt="add-image"
                      className='object-cover w-content h-full cursor-pointer' 
                      onClick={() => document.getElementById('ImgInput').click()}
                      />
                 
                  <input
                      type="file"
                      id="ImgInput"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={handleChange}
                  />
                  {selectedFile&&<>
                    <button className="absolute top-0 right-0 mt-2 mr-2 rounded-full bg-white text-gray-400 px-2 hover:text-white hover:bg-[#1E78C8] ease-in-out duration-300 font-bold" onClick={() => document.getElementById('ImgInput').click()}><span className="material-symbols-outlined text-[16px] mt-2"> edit </span></button>
                    <button className="absolute bottom-0 right-0 mb-2 mr-2 rounded-full bg-white text-gray-400 px-2 hover:text-white hover:bg-red-600 ease-in-out duration-300 font-bold"><span className="material-symbols-outlined text-[16px] mt-2" onClick={onDelete}> delete </span></button>
                  </>}
              </div>    
            </div>
            </div>
            <div className='flex w-[80%] my-4 mb-6 h-10 text-sm'>
                <button className='font-bold w-[50%] mx-2 rounded-[10px] bg-gray-400 text-white cursor-pointer border-2 border-gray-400 hover:bg-white hover:text-gray-600 ease-in-out duration-300' onClick={onCancel}>Cancel</button>
                <button className='font-bold w-[50%] bg-[#1E78C8] mx-2 text-white rounded-[10px] cursor-pointer border-2 border-[#1E78C8] hover:bg-white hover:text-[#1E78C8] ease-in-out duration-300' onClick={onCreateElement}>{!createLoader?<p>Create</p>: <Loader/>}</button>
            </div> 
        </div>
    );
}

export default CreateNewElement;