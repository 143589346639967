import React, { useEffect, useState } from 'react';

const AddPrivacyPolicy = ({onClose, leadCapture, setLeadCapture}) => {
    
    return (
        <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 flex flex-col bg-gray-100 w-[500px] h-[250px] rounded-[10px] shadow-md items-center'>           
            <div className='m-4 mt-3 w-[80%]'>
              <p className='text-base font-bold'>Add Custom Privacy Policy</p>
            </div>
            <div className='m-4 w-[80%]'>
                <p className='text-sm'>Enter Link for your custom privacy policy</p>
                <input type="text" className='w-[80%] border border-gray-400 my-2 px-2 py-1 rounded-[10px] ' onChange={(e)=>setLeadCapture({...leadCapture, privacyPolicy: e.target.value})} value={leadCapture?.privacyPolicy}/>
                <p className='text-[12px] text-gray-400'>This is will be made as a check while capturing lead.</p>
            </div>
            <div className='flex w-[80%] my-4 h-10 text-sm'>
                <button className='font-bold w-[50%] bg-[#1E78C8] mx-2 text-white rounded-[10px] cursor-pointer border-2 border-[#1E78C8] hover:bg-white hover:text-[#1E78C8] ease-in-out duration-300' onClick={onClose}>OK</button>
                <button className='font-bold w-[50%] mx-2 rounded-[10px] bg-gray-400 text-white cursor-pointer border-2 border-gray-400 hover:bg-white hover:text-gray-600 ease-in-out duration-300' onClick={onClose}>Cancel</button>
            </div> 
        </div>
    );
}

export default AddPrivacyPolicy;