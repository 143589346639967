import axios from "axios";

export const BASE_URL = 'https://timeline.ueu-services.com/';
export const MEDIA_URL = 'https://ueu-dev.s3.us-east-2.amazonaws.com/'

const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});
export const login = async ({username, password}) => {
  try {
    const response = await axios.post("https://ueu-dashboards.com/api/v1/login", {username, password});
    return response.data;
  } catch (error) {
    if (error?.status === 500) return error.message;
    return error?.response?.data;
  }
};
export const getTimeLines = async () => {
    try {
      const response = await api.get("/timeLine");
      return response.data;
    } catch (error) {
      if (error?.status === 500) return error.message;
      return error?.response?.data;
    }
  };
export const getBackgroundImages = async () => {
    try {
      const response = await api.get("/backgroundImages");
      return response.data;
    } catch (error) {
      if (error?.status === 500) return error.message;
      return error?.response?.data;
    }
  };
export const getSounds = async () => {
    try {
      const response = await api.get("/sounds");
      return response.data;
    } catch (error) {
      if (error?.status === 500) return error.message;
      return error?.response?.data;
    }
  };
export const getProjects = async () => {
    try {
      const response = await api.get("/project");
      return response.data;
    } catch (error) {
      if (error?.status === 500) return error.message;
      return error?.response?.data;
    }
  };
export const getGroups = async () => {
    try {
      const response = await api.get("/group");
      return response.data;
    } catch (error) {
      if (error?.status === 500) return error.message;
      return error?.response?.data;
    }
  };
export const fetchTimeLine = async ({timeLineUrl}) => {
    try {
      
      const url = timeLineUrl ? `timeLine?timeLineUrl=${timeLineUrl}` : 'timeLine';
      
      const response = await api.get(url);
  
      return response.data;
    } catch (error) {
     
      console.error('Error fetching timeLines:', error);
      throw error;
    }
  };
  export const uploadFile = async (file, username) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('username', username);
      console.log("Sending File: ", file)
     
      const fileName = file.name;
  
      const response = await api.post('timeLine/savefile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
  
      return { response: response.data};
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };
  export const uploadFiles = async (formData) => {
    try {
     
      console.log("sending", formData)
      formData.forEach((value, key) => {
        console.log(key, value);
      });
      const response = await api.post('timeLine/savefiles', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
  
      return { response: response.data };
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };
  
export const createTimeLine = async (timeLineData, timeLineUrl, groupId) => {
    try {
      
      console.log("Sending", timeLineData, timeLineUrl, groupId)
      const response = await api.post('timeLine', timeLineData, timeLineUrl, groupId);
      return response
    } catch (error) {
      console.error('Error creating timeLine:', error);
      throw error;
    }
};
export const createProject = async (projectName, groups) => {
    try {
      
      
      const response = await api.post('project', projectName, groups);
      return response
    } catch (error) {
      console.error('Error creating project:', error);
      throw error;
    }
};
export const deleteTimeLine = async (timeLineId) => {
    try {
      
      const response = await api.delete('timeLine', { data: { timeLineId } });
      return response
    } catch (error) {
      console.error('Error deleting timeLine:', error);
      throw error;
    }
};
export const deleteProject = async (projectId) => {
  try {
      
      const response = await api.delete('project', { data: { projectId } });
      return response;
  } catch (error) {
      console.error('Error deleting project:', error);
      throw error;
  }
};

export const deleteGroup = async (groupId) => {
    try {
      
      
      const response = await api.delete('group', { data: { groupId } });
      return response
    } catch (error) {
      console.error('Error deleting group:', error);
      throw error;
    }
};
export const createGroup = async (groupName, timeLines, projectId) => {
    try {
      
      console.log("Sending", groupName, timeLines, projectId)
      const response = await api.post('group', groupName, timeLines, projectId);
      return response
    } catch (error) {
      console.error('Error creating group:', error);
      throw error;
    }
};
export const verifyEmail = async (email) => {
    try {
      
      
      const response = await api.post('verifyEmail/', email);
      return response
    } catch (error) {
      console.error('Error sending mail:', error);
      throw error;
    }
};
export const notifyEmail = async (email, timeLineUrl, timeLineTitle) => {
    try {
      
      
      const response = await api.post('notifyEmail/', email, timeLineUrl, timeLineTitle);
      return response
    } catch (error) {
      console.error('Error sending mail:', error);
      throw error;
    }
};
export const editTimeLine = async (timeLineData, timeLineUrl, timeLineName, customTimeLineUrl) => {
    try {
      
      
      const response = await api.put('timeLine', timeLineData, timeLineUrl, timeLineName, customTimeLineUrl);
      return response
    } catch (error) {
      console.error('Error editing timeLine:', error);
      throw error;
    }
};
export const editProject = async ({projectName, projectId, project2Id, item, copy}) => {
    try {
      
      
      const response = await api.put('project', {projectName, projectId, project2Id, item, copy});
      return response
    } catch (error) {
      console.error('Error editing project:', error);
      throw error;
    }
};
export const editGroup = async ({groupName, groupId, group2Id, item, copy}) => {
    try {
      
      
      const response = await api.put('group', {groupName, groupId, group2Id, item, copy});
      return response
    } catch (error) {
      console.error('Error editing group:', error);
      throw error;
    }
};