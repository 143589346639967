import React, {useState, useEffect} from 'react';
import { editTimeLine, notifyEmail } from '../api';
import { verifyEmail } from '../api';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchTimeLine } from '../api';
import Spinner from '../components/spinner';
import TimeLineViewer from '../components/TimelineViewer';
import axios from 'axios';
import { BASE_URL } from '../api';
import Loader from '../common/loader';


const ViewPage = (props) => {
    const { '*' : url } = useParams();
    const [viewMail, setViewMail] = useState(false)
    const navigate = useNavigate()
    const [logoUrl, setLogoUrl] = useState('')
    const [privacy, setPrivacy] = useState()
    const [locked, setLocked] = useState(true)
    const [backgroundUrl, setBackgroundUrl] = useState('')
    const [imageFiles, setImageFiles] = useState()
    const [pdfFile, setFile] = useState()
    const [titleDetails, setTitleDetials] = useState()
    const [password, setPassword] = useState('');
    const [buttons, setButtons] = useState()
    const [leadCapture, setLeadCapture] = useState()
    const leads = []
    const [lead, setLead] = useState({
      name: '',
      email: '',
      company: '',
      phone: '',
      customField1: '',
      customField2: '',
    })
    const [isVerified, setVerified] = useState(false)
    const [timeLineData, setTimeLineData] = useState()
    const [isCaptured, setCaptured] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [isHovered, setHover] = useState(false)
    const [analytics, setAnalytics] = useState(false)
    const [automation, setAutomation] = useState(false)
    const [settings, setSettings] = useState(false)
    const [controls, setControls] = useState(false)
    const [leadLoader, setLeadLoader] = useState(false)
    const [leadError, setLeadError] = useState()
    const [themeStyle, setThemeStyle] = useState(1)
    const [elements, setElements] = useState([])
    
    const[loading, setLoading] = useState()
    const[vOTP, setVOTP] = useState()
    const[OTP, setOTP] = useState()
  
    const [hideMenu, setHideMenu] = useState()
    const toggleMenu = () => {
      setHideMenu(!hideMenu)
    }

    const onSubmit = async(e) =>{
      setError()
      if(e){
        e.preventDefault()
      }
      setLeadLoader(true)
      leads.push(lead)
      try {
        if(leadCapture?.verifyEmail.status&&!isVerified){
          const response = await verifyEmail({email: lead?.email});
          const {data} = response;
          const {OTP} = data
          setVOTP(OTP)
          setLeadLoader(false)
        }else{
          const newTimeLineData = {
            ...timeLineData,
            leads: leads
          }

          
          const response = await editTimeLine({timeLineData: newTimeLineData, timeLineUrl: url});
         
          if(timeLineData?.automation?.pebWebhookUrl){
            const formData = {
              ...lead
            }
            const response = await axios.post(`${timeLineData?.automation?.pebWebhookUrl}`, formData);
            console.log("RESPONSE", response)
          }
          setError("SUCCESS")
          setLeadLoader(false)
          setCaptured(true)
          console.log(response);
        }
        
      } catch (error) {
        setLeadLoader(false)
        setError("Server error: Please try again later.")
        console.error(error);
      }
    }
  
    const onSkip = () =>{
      setCaptured(true)
    }
  
    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };
    const [emailError, setEmailError] = useState()
    const handleLeadChange = (event) => {
      setEmailError('')
      setLead({
        ...lead,
        [event.target.name]: event.target.value
      });
    };
    const handleEmailBlur = (event) => {
      if (event.target.name === 'email'&&event.target.value) {

        setEmailError('')
        const enteredEmail = event.target.value.toLowerCase(); // Convert entered email to lowercase for consistency
        const allowedEmails = leadCapture.allowedEmails.toLowerCase().split(';'); // Convert allowed emails to lowercase and split into an array
        const enteredDomain = enteredEmail.split('@')[1]; // Get the domain part of the entered email

        // Check if the entered domain is included in the allowed emails array
        const isAllowedEmail = allowedEmails.some(allowedEmail => {
          return enteredDomain === allowedEmail || `@${enteredDomain}` === `${allowedEmail}`;
        });
    
        if (!isAllowedEmail) {
         
          console.log('This email provider is not allowed.');
          setEmailError('This email provider is not allowed.')
        }
      }
    };
    

    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };
    useEffect(()=>{
      if(timeLineData?.settings){
        if(!timeLineData?.settings?.published)
            navigate('/')
      }
    }, [timeLineData])
    useEffect(() => {
      if (analytics) {
        if (analytics.fbPixelId) {
          // Meta Pixel Code (Facebook Pixel)
          /* eslint-disable no-unused-expressions, no-undef */
          !function(f,b,e,v,n,t,s) {
            if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)
          }(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', analytics.fbPixelId);
          fbq('track', 'PageView');
          /* eslint-enable no-unused-expressions, no-undef */
        }
  
        if (analytics.gAnalyticsId) {
          // Google tag (gtag.js)
          /* eslint-disable no-undef */
          const gtagScript = document.createElement('script');
          gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${analytics.gAnalyticsId}`;
          gtagScript.async = true;
          document.head.appendChild(gtagScript);
  
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', analytics.gAnalyticsId);
          /* eslint-enable no-undef */
        }
  
        if (analytics.gTagManagerId) {
          // Google Tag Manager
          /* eslint-disable no-undef */
          const gtmScript = document.createElement('script');
          gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${analytics.gTagManagerId}');`;
          document.head.appendChild(gtmScript);
  
          // Google Tag Manager (noscript)
          const gtmNoscript = document.createElement('noscript');
          gtmNoscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${analytics.gTagManagerId}"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
          document.body.insertBefore(gtmNoscript, document.body.firstChild);
          /* eslint-enable no-undef */
        }
      }
    }, [analytics]);
    
    useEffect(()=>{
      const fetchData = async () => {
        try {    
          console.log("Fetching", url)     
          const data = await fetchTimeLine({ timeLineUrl: url }); 
          const {timeLineData} = data
          setTimeLineData(timeLineData)
          const {imageFiles, titleDetails, pdfLink, elements, themeStyle, privacy, buttons, leadCapture, analytics, automation, leads, controls, settings} = timeLineData
          setTitleDetials(titleDetails)
          setImageFiles(imageFiles)
          setFile(pdfLink);
          setPrivacy(privacy)
          setButtons(buttons)
          setLeadCapture(leadCapture)
          setAnalytics(analytics)
          setAutomation(automation)
          setControls(controls)
          setThemeStyle(themeStyle)
          setElements(elements)
          setSettings(settings)
          if(leads){
            leads.push(...leads);
          }
        } catch (error) {
          console.error('Error fetching timeLines:', error);
        }
      };
      fetchData();
            
      
      

    }, [url])
    useEffect(() => {

    
      const replaceLocalUrlWithBaseUrl = (url) => {
        if (typeof url === 'string' && /^https?:\/\/\S+$/.test(url)) {
          if (url.includes('http://127.0.0.1:8000/')) {
            return url.replace('http://127.0.0.1:8000/', BASE_URL);
          }
          return url;
        }
        return '';
      };
    
      // Check and set logo image URL
      if (imageFiles?.logoImage instanceof File) {
        const reader = new FileReader();
        reader.onload = () => {
          setLogoUrl(reader.result);
        };
        reader.readAsDataURL(imageFiles?.logoImage);
      } else {
        setLogoUrl(replaceLocalUrlWithBaseUrl(imageFiles?.logoImage));
      }
    
      // Check and set background image URL
      if (imageFiles?.backgroundImage instanceof File) {
        const reader = new FileReader();
        reader.onload = () => {
          setBackgroundUrl(reader.result);
        };
        reader.readAsDataURL(imageFiles?.backgroundImage);
      } else {
        setBackgroundUrl(replaceLocalUrlWithBaseUrl(imageFiles?.backgroundImage));
      }
    
      // Check and set favicon image URL
      if (imageFiles?.faviconImage) {
        const faviconLink = document.querySelector('link[rel="icon"]');
        if (faviconLink) {
          faviconLink.href = replaceLocalUrlWithBaseUrl(imageFiles.faviconImage);
        } else {
          const newFaviconLink = document.createElement('link');
          newFaviconLink.rel = 'icon';
          newFaviconLink.href = replaceLocalUrlWithBaseUrl(imageFiles.faviconImage);
          document.head.appendChild(newFaviconLink);
        }
      }
    }, [imageFiles, setImageFiles]);
    
    const [error, setError] = useState()
    useEffect(()=>{
      if(password){
        if(password.toString() === privacy?.password?.toString() ){
          setLocked(false)
          setError("")
        }else{
          setError("PASSWORD DOESN'T MATCH")
        }
      }
    }, [password])
    useEffect(()=>{
      

    }, [lead])
    
    useEffect(() => {
      const sendEmails = async (emails) => {
        const emailAddresses = emails.split(';').map(email => email.trim()); 
        try{
          for (const email of emailAddresses) { 
            const response = await notifyEmail({ email: email, timeLineUrl: url, timeLineTitle: timeLineData?.titleDetails?.title }); // Assuming notifyEmail accepts an email parameter
            console.log(response)
            setViewMail(true)

          }
        }catch(error){
          console.error("Unable to send notification mail, server failed", error)
        }
        
      }
    
      if (settings?.mailOnView) {

        if(settings?.sendEmailsTo&&!viewMail){

          sendEmails(settings?.sendEmailsTo);
        }
      }
    
    }, [settings]);
    
    useEffect(()=>{
      if(OTP){

        if(OTP.toString()===vOTP.toString()){
          setError("Verified")
          setVerified(true)
          setCaptured(true)
          onSubmit()

        }else{
          setError("OTP DOESN'T MATCH")
        }
      }
    }, [OTP])
    
    return (
        <div className='flex w-full h-screen bg-gray-100 text-base overflow-auto' >
            
           
                <div className='flex w-full h-screen p-10 m-0 bg-white relative overflow-auto'>
                {loading?(
                    <>
                    <Spinner message={'LOADING TIMELINE'}/>
                    </>
                ):(
                  <>
                  <div className="absolute w-full h-full left-0 top-0">
                      {imageFiles?.backgroundColor && !imageFiles?.backgroundImage?(
                        <div className={`object-cover w-full h-full`} style={{'background-color': `${imageFiles.backgroundColor}`}}></div>
                      ):(
                        <img src={backgroundUrl} alt="" className='object-cover w-full h-full'/>
                      )}
                  </div>
                  {privacy?.privacyType==='private'&&locked?(
                  <>
                  <div className='grid items-center w-[50%] mx-auto h-screen relative'>
                  <div className='bg-black rounded-[30px] p-8 bg-opacity-80 flex flex-col items-center -mt-6'>
                    {logoUrl&&
                      <div className='text-[12px] flex font-bold flex items-center justify-center max-w-max h-[100px]'>
                        <img src={logoUrl} alt="logo-img" className='object-cover w-full h-full'/>
                      </div>
                    }
                  <label htmlFor="password" className='font-bold text-[20px] my-2 mb-4' style={{color: `${titleDetails.color}`}}>{timeLineData?.leadCapture?.passwordTitle}</label>
                  <div className="relative w-full mb-8">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="border border-gray w-full pr-10 px-2 py-2 rounded-[10px]" // Add paddingRight to make space for the button
                      value={password}
                      onChange={handlePasswordChange}
                      placeholder={timeLineData?.leadCapture?.passwordText}
                    />
                    <button
                      onClick={toggleShowPassword}
                      className="absolute inset-y-0 right-0 px-3"
                    >
                      {showPassword ? <span class="material-symbols-outlined"> visibility_off </span> : <span class="material-symbols-outlined"> visibility </span>}
                    </button>
                    </div>
                    <p className='font-bold text-red-600 mb-2'>{error}</p>
                    <button className='font-bold p-1.5 w-1/4 bg-[#1E78C8] mx-2 text-white rounded-[10px] cursor-pointer border-2 border-[#1E78C8] hover:bg-transparent hover:text-[#1E78C8] ease-in-out duration-300'>{leadLoader? <Loader/>: <p>{timeLineData?.leadCapture?.passwordSubmit}</p>}</button>
                    </div>
                  </div>

                  </>
                ):(
                  <>
                  
                  {leadCapture?.enabled && !isCaptured?(
                    <>
                    {vOTP?(
                        <>
                        <div className='grid items-center w-[40%] mx-auto h-screen relative'>
                          <div className='bg-black rounded-[30px] p-8 bg-opacity-80 flex flex-col items-center text-[14px]'>
                            {logoUrl&&
                              <div className='text-[12px] flex font-bold flex items-center justify-center max-w-max h-[50px]'>
                                <img src={logoUrl} alt="logo-img" className='object-cover w-full h-full'/>
                              </div>
                            }
                            <label htmlFor="otp" className='font-bold text-[20px] my-2 mb-4' style={{color: `${titleDetails.color}`}}>{timeLineData?.leadCapture?.emailVerficationTitle}</label>
                            <div className="relative w-full mb-8">
                              <input
                                type={'text'}
                                className="border border-gray w-full pr-10 px-2 py-2 rounded-[10px]" // Add paddingRight to make space for the button
                                value={OTP}

                                maxLength={4}
                                onChange={(e)=> setOTP(e.target.value)}
                                placeholder={timeLineData?.leadCapture?.emailVerficationTitle}
                              />
                              
                              </div>
                              <p className='font-bold text-red-600'>{error}</p>
                              <button className='font-bold p-1.5 w-1/4 bg-[#1E78C8] mx-2 text-white rounded-[10px] cursor-pointer border-2 border-[#1E78C8] hover:bg-transparent hover:text-[#1E78C8] ease-in-out duration-300'>{leadLoader? <Loader/>: <p>{timeLineData?.leadCapture?.verifyEmailButton}</p>}</button>

                            </div>
                         
                          </div>
                        </>
                    ):(
                      <>
                      
                    <div className='grid items-center w-[40%] mx-auto relative overflow-auto'>
                     <div className='bg-black rounded-[30px] p-8 bg-opacity-80 flex flex-col h-auto items-center text-[14px]'>
                       {logoUrl&&
                          <div className='text-[12px] flex font-bold flex items-center justify-center max-w-max h-[50px]'>
                            <img src={logoUrl} alt="logo-img" className='object-cover w-full h-full'/>
                          </div>
                       }
                       <label htmlFor="leads" className='font-bold text-[20px] my-2 mb-4' style={{color: `${titleDetails.color}`}}>{leadCapture?.leadTitle}</label>
                       <form onSubmit={onSubmit} className='w-full'>
                        {leadCapture?.name?.status&&(
                          <div className="relative w-full mb-2 text-left">
                            <label className='text-white ml-2'>{leadCapture?.name?.value} {leadCapture?.name?.required && <span className="text-red-500">*</span>}</label>
                            <input
                              type={'text'}
                              name='name'
                              className="border border-gray w-full pr-10 px-2 py-2 rounded-[10px]" // Add paddingRight to make space for the button
                              value={lead?.name}
                              onChange={handleLeadChange}
                              placeholder='Enter your name'
                              required = {leadCapture?.name?.required}
                            />
  
                          </div>
                        )}
                        {leadCapture?.email?.status&&(
                          <div className="relative w-full mb-2 text-left">
                            <label className='text-white ml-2'>{leadCapture?.email?.value} {leadCapture?.email?.required && <span className="text-red-500">*</span>}</label>
                            <input
                              type={'text'}
                              name='email'
                              className="border border-gray w-full pr-10 px-2 py-2 rounded-[10px]" // Add paddingRight to make space for the button
                              value={lead?.email}
                              onChange={handleLeadChange}
                              onBlur={handleEmailBlur}
                              placeholder='Enter your email'
                              required = {leadCapture?.email?.required}
                            />
                            {leadCapture?.verifyEmail?.status && <p className='font-bold text-gray-400 text-[12px]'>Note* this email will be verified through OTP</p>}
                            <p className='font-bold text-red-600 text-[12px]'>{emailError}</p>
                          </div>
                        )}
                        {leadCapture?.phone?.status&&(
                          <div className="relative w-full mb-2 text-left">
                            <label className='text-white ml-2'>{leadCapture?.phone?.value} {leadCapture?.phone?.required && <span className="text-red-500">*</span>}</label>
                            <input
                              type={'text'}
                              name='phone'
                              className="border border-gray w-full pr-10 px-2 py-2 rounded-[10px]" // Add paddingRight to make space for the button
                              value={lead?.phone}
                              onChange={handleLeadChange}
                              placeholder='Enter your phone'
                              required = {leadCapture?.phone?.required}
                            />

                          </div>
                        )}
                        {leadCapture?.company?.status&&(
                          <div className="relative w-full mb-2 text-left">
                            <label className='text-white ml-2'>{leadCapture?.company?.value} {leadCapture?.company?.required && <span className="text-red-500">*</span>}</label>
                            <input
                              type={'text'}
                              name='company'
                              className="border border-gray w-full pr-10 px-2 py-2 rounded-[10px]" // Add paddingRight to make space for the button
                              value={lead?.company}
                              onChange={handleLeadChange}
                              placeholder='Enter your company'
                              required = {leadCapture?.company?.required}
                            />
                            
                          </div>
                        )}
                        {leadCapture?.customField1?.status&&(
                          <div className="relative w-full mb-2 text-left">
                            <label className='text-white ml-2'>{leadCapture?.customField1?.value} {leadCapture?.customField1?.required && <span className="text-red-500">*</span>}</label>
                            <input
                              type={'text'}
                              name='customField1'
                              className="border border-gray w-full pr-10 px-2 py-2 rounded-[10px]" // Add paddingRight to make space for the button
                              value={lead?.customField1}
                              onChange={handleLeadChange}
                              placeholder={`Enter ${leadCapture?.customField1?.value}`}
                              required = {leadCapture?.customField1?.required}
                            />

                          </div>
                        )}
                        {leadCapture?.customField2?.status&&(
                          <div className="relative w-full mb-2 text-left">
                            <label className='text-white ml-2'>{leadCapture?.customField2?.value} {leadCapture?.customField2?.required && <span className="text-red-500">*</span>}</label>
                            <input
                              type={'text'}
                              name='customField2'
                              className="border border-gray w-full pr-10 px-2 py-2 rounded-[10px]" // Add paddingRight to make space for the button
                              value={lead?.customField2}
                              onChange={handleLeadChange}
                              placeholder={`Enter ${leadCapture?.customField2?.value}`}
                              required = {leadCapture?.customField2?.required}
                            />

                          </div>
                        )}
                        <div className='text-left text-white'>
                          {leadCapture?.privacyPolicy&&<p>By clicking submit you agree to our <a href={leadCapture?.privacyPolicy} target='_blank' className='font-bold text-[#1E78C8]'>Privacy Policy</a></p>}
                        </div>
                        <div className='items-center flex w-full text-center justify-center mt-4'>
                          <button 
                            type='submit' 
                            disabled={emailError ? true : false} 
                            className={`font-bold p-1.5 w-1/4 bg-[#1E78C8] mx-2 text-white rounded-[10px] border-2 border-[#1E78C8] hover:bg-transparent hover:text-[#1E78C8] ease-in-out duration-300 ${
                              emailError ? 'cursor-not-allowed' : 'cursor-pointer'
                            }`}
                          >
                            {leadLoader ? <Loader/> : <>{leadCapture?.submit?.value}</>}
                          </button>                          
                          {leadCapture?.allowSkip?.status &&<button onClick={onSkip} className='font-bold p-1.5 w-1/4 mx-2 rounded-[10px] bg-gray-400 text-white cursor-pointer border-2 border-gray-400 hover:bg-transparent hover:text-gray-400 ease-in-out duration-300'>{leadCapture?.skip?.value}</button>}  
                        </div>
                        </form>
                       <p className='font-bold text-red-600 mt-2'>{error}</p>

                       </div>
                     </div>
                      </>
                    )}
                    </>
                  ):(
                    <>
                    {!pdfFile ? (
                    <>
                  
                     
                     <div className='flex justify-center relative w-full'>
                         {buttons&&(
                           <div className='absolute right-0 top-0 flex flex-col z-50'>
                            <a className='px-3 py-2 text-sm rounded-[10px] bg-opacity-30 my-2 cursor-pointer bg-[#1E78C8] text-white w-[130px]' onClick={toggleMenu}>{hideMenu?"Show ":"Hide "} Controls</a>
                            {buttons.button1&&(
                              <a className='px-3 py-2 text-sm rounded-[10px] my-2 cursor-pointer' style={{
                                backgroundColor: buttons?.button1?.bt1Color,
                                color: buttons?.button1?.bt1TextColor,
                                transition: "background-color 0.3s, color 0.3s",
                                ":hover": {
                                  backgroundColor: buttons?.button1?.bt1TextColor,
                                  color: buttons?.button1?.bt1Color
                                }
                              }}
                              href={buttons?.button1?.bt1Url}
                              target= '_blank'
                              >{buttons?.button1?.bt1Text}</a>
                            )}
                            {buttons.button2&&(
                              <a className='px-3 py-2 text-sm rounded-[10px] my-2 cursor-pointer' style={{
                                backgroundColor: buttons?.button2?.bt2Color,
                                color: buttons?.button2?.bt2TextColor
                              }}
                              href={buttons?.button2?.bt2Url}
                              target= '_blank'
                              >{buttons?.button2?.bt2Text}</a>
                            )}
                          </div>
                        )}
                      <p className={`absolute text-[16px] left-0 -top-6 flex font-bold`}  onMouseEnter={() => setHover(true)}onMouseLeave={() => setHover(false)} style={{color: `${titleDetails?.color}`}}>{titleDetails?.title}{titleDetails?.description&&<div className='relative'>
                        <span class="material-symbols-outlined cursor-pointer relative text-[18px] mt-1" >priority_high</span>
                        {isHovered && (
                            <div className="absolute bg-gray-400 h-auto w-[150px] text-white text-[12px] px-5 rounded-[10px] z-50"><p>{titleDetails.description}</p></div>
                        )}
                        </div>}</p>
                      {logoUrl&&
                        <div className='text-[12px] fixed bottom-6 right-10 flex font-bold flex items-center justify-center max-w-max h-[50px]'>
                          <a href={imageFiles?.logoRedirect|| ''} className='w-full h-full' target='_blank'>
                            <img src={logoUrl} alt="logo-img" className='object-cover w-full h-full'/>
                          </a>
                        </div>
                      }
                      <TimeLineViewer pdfUrl={pdfFile} elements={elements} themeStyle={themeStyle} height={540} backgroundImage={imageFiles?.backgroundImage} settings={settings} controls = {controls} hideMenu={hideMenu} />
                     </div>
                   
                      </>
                    ):(
                    <div className='w-full h-full grid grid-col-1 items-center text-center'>
                      <p className='font-bold text-[30px] text-[#1E78C8]'>Please wait for the files to load!</p>
                      <Spinner/>
                    </div>
                )}
                    </>
                  )}
                  </>
                )}
                  </>
                )}
                </div>
               
           
            
        </div>
    );
}

export default ViewPage;