import React, {useState, useEffect, useRef} from 'react';
import { useReactToPrint } from 'react-to-print';
import Header from '../components/header';
import Sidebar from '../components/sidebar';
import { createTimeLine, uploadFile } from '../api';
import { uploadFiles } from '../api';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchTimeLine } from '../api';
import { editTimeLine } from '../api';
import Spinner from '../components/spinner';
import { MEDIA_URL } from '../api';
import TimeLineViewer from '../components/TimelineViewer';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import LeftBar from '../components/leftBar';
function generateURL() {
  
  const randomString = Math.random().toString(36).substring(2, 6).toUpperCase();

  
  const timestamp = Date.now().toString(16).toUpperCase();

  
  const randomId = timestamp.substring(0, 8) + '-' + timestamp.substring(8, 12) + '-' + randomString;

  return randomId;
}

const EditPage = (props) => {
  
    const { '*' : url } = useParams();
    const [userDetails, setUserDetails] = useState()
    const navigate = useNavigate()
    const [logoUrl, setLogoUrl] = useState(undefined)
    const [backgroundUrl, setBackgroundUrl] = useState('')
    const [imageFiles, setImageFiles] = useState()
    const timeLineUrl = url || undefined
    const [pdfFile, setFile] = useState()
    const [pdfLink, setPdfLink] = useState()
    const [titleDetails, setTitleDetials] = useState({
      title: "TimelineName",
      color: "gray",
      description:"",
      url: timeLineUrl
    })
    const [buttons, setButtons] = useState({
      button1:{
        active: false
      },
      button2:{
        active: false
      },
    })
    const [leadCapture, setLeadCapture] = useState()
    const [message, setMessage] = useState()
    const [messageColor, setMessageColor] = useState()
    const [loading, isLoading] = useState(false)
    const [isHovered, setHover] = useState(false)
    const [updatedImageLinks, setUpdatedImageLinks] = useState("")
    const [privacy, setPrivacy] = useState("")
    const [settings, setSettings] = useState({
    
    })
    console.log("url", url)
    const [controls, setControls] = useState({
      
        autoplay: "0",
      
    })

    const [newElementOpen, setNewElementOpen] = useState(false)
    const onElementOpen = () =>{
        setNewElementOpen(!newElementOpen)
    } 
    useEffect(()=>{
      const onCreate = async() => {
        isLoading(true)
        setLoaderMessage("Initializing Timeline, Please Hold!")
        try{
            const currentName = "New Timeline"
            console.log("SENDING", currentName)
            if(!timeLineUrl){
              
              const url = generateURL()
              const timeLineData = {
                  titleDetails: {
                      title: currentName,
                      url: url
                  },
                  themeStyle: 3,
                  elements: [],
              }
              setLoaderMessage("Initializing Timeline, 10%")
              const response = await createTimeLine({timeLineData: timeLineData, timeLineUrl: url, customTimeLineUrl: url, timeLineName: currentName});
              setLoaderMessage("Timeline Initialized 100%")
              console.log("RESPONSE", response)
              isLoading(false)
              navigate(`/edit/${encodeURIComponent(url)}`);
            }
            
        }catch(error){
            console.error("ERROR", error)
            isLoading(false)
        }
      }
      onCreate()
    },[])
    const [analytics, setAnalytics] = useState()
    const [automation, setAutomation] = useState()
    const [fetching, setFetching] = useState(true)
    const [timeLineData, setTimeLineData] = useState()
    const [themeStyle, setThemeStyle] = useState(1)
    const [elements, setElements] = useState([])
    const [newElementDetails, setNewElementDetails] = useState()
    const componentRef = useRef();
    const [timelineLoaderMessage, setLoaderMessage] = useState("")
    const handleConvertToPdf = useReactToPrint({
      content: () => componentRef.current,
      
    });
    useEffect(()=>{
      if(localStorage.getItem("user")){
          const user = JSON.parse(localStorage.getItem("user"))
          console.log(user)
          setUserDetails(user)
      }else{
          navigate('/')
      }
    }, [])
    console.log(elements)
    const handleConvertToImage = useReactToPrint({
      
      onAfterPrint: () => {
        const content = componentRef.current;
        // Create canvas
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
    
        // Set canvas dimensions to match the rendered component
        canvas.width = content.offsetWidth;
        canvas.height = content.offsetHeight;
    
        // Draw the component's content onto the canvas
        context.drawImage(content, 0, 0, content.offsetWidth, content.offsetHeight);
    
        // Convert canvas to data URL
        const imageData = canvas.toDataURL('image/png');
    
        // Download the image
        const link = document.createElement('a');
        link.href = imageData;
        link.download = 'exported_image.png';
        link.click();
      },
    });

    useEffect(()=>{
      if(localStorage.getItem("user")){
          const user = JSON.parse(localStorage.getItem("user"))
          
      }else{
         navigate('/')
      }
    }, [])
    const [createLoader, setCreateLoader] = useState(false)
    const [createMessage, setCreateMessage] = useState()
    const onCreateElement = async() =>{
      setCreateLoader(true)
      if(!newElementDetails){
        setCreateMessage("All Fields Required")
        
        setCreateLoader(false)
        setTimeout(() => {
          setCreateMessage('')
        }, 2000);
        return;
      }
      const imageFile = newElementDetails?.elementImage
      try {
        if (newElementDetails?.elementImage instanceof File) {
          
          const renamedBlob = new Blob([imageFile], { type: imageFile.type });
          const renamedFile = new File([renamedBlob], `${timeLineUrl}${elements?.length+1}-imageFile.${imageFile.name.split('.').pop()}`, { type: imageFile.type });
          setCreateMessage("Saving Image... 55%")
          const { response } = await uploadFile(renamedFile, userDetails?.username);
          const imageLink = `${MEDIA_URL}`+'media/'+userDetails?.username+'/'+renamedFile.name
          
          setNewElementDetails({...newElementDetails, elementImage: imageLink})
          const updatedElementDetails = { ...newElementDetails, elementImage: imageLink };

          
          const updatedElements = [...elements, updatedElementDetails];

    
          setElements(updatedElements);
          setCreateLoader(false)
          setCreateMessage("Created... 100%")
          setTimeout(() => {
            setCreateMessage('')
          }, 2000);
          onElementOpen();
          return imageLink;

        } else {
          console.warn('No IMAGE file provided, link detected returning link');
          setCreateLoader(false)
          return imageFile;
        }
      } catch (error) {
        setCreateMessage("Unknown Error Occured, please try again later!")
        setMessageColor('text-red-400')
        setTimeout(() => {
          setCreateMessage('')
        }, 2000);
        console.error('Error uploading IMAGE file:', error);
        setCreateLoader(false)
        throw error;
      }
    }
    
    useEffect(()=>{
      
      console.log(newElementDetails);
      
      console.log(elements)
    }, [newElementDetails])
    useEffect(()=>{
      const fetchData = async () => {
        try {
          
          const data = await fetchTimeLine({ timeLineUrl: url }); 
          const {timeLineData} = data
          setTimeLineData(timeLineData)
          const {imageFiles, titleDetails, themeStyle, elements, pdfLink, privacy, buttons, leadCapture, settings,controls , automation, analytics} = timeLineData
          setTitleDetials(titleDetails)
          setImageFiles(imageFiles)
          setFile(pdfLink);
          setPrivacy(privacy);
          if(buttons)
            setButtons(buttons);
          setLeadCapture(leadCapture)
          setThemeStyle(themeStyle)
          setElements(elements)
          if(settings)
            setSettings(settings)
          if(controls)
            setControls(controls)
          setAnalytics(analytics)
          setAutomation(automation)
          isLoading(false);
          setFetching(false)
        } catch (error) {
          // Handle errors if any
          console.error('Error fetching timeLines:', error);
        }
      };
      setFetching(true)
      fetchData();
            
      
      

    }, [url])
    
    const uploadImagesAndUpdateLinks = async () => {
      try {
        setMessage("Status: Saving... 10%")
        const updatedImageFiles = { ...imageFiles };
        const fileLinks = {};
        const formData = new FormData()

        for (const key in imageFiles) {
          if (imageFiles[key] instanceof File) {
            const file = imageFiles[key];
            const renamedBlob = new Blob([file], { type: file.type });
            const renamedFile = new File([renamedBlob], `${timeLineUrl}-${key}.${file.name.split('.').pop()}`, { type: file.type });
            formData.append('files', renamedFile);
            updatedImageFiles[key] = `${MEDIA_URL}`+'media/'+userDetails?.username+'/'+renamedFile.name;
          }
        } 
        formData.append('username', userDetails?.username)
        const response = await uploadFiles(formData);
        setMessage("Status: Saving... 35%")

        setUpdatedImageLinks(updatedImageFiles)
        return updatedImageFiles;
      } catch (error) {
        console.error('Error uploading image files:', error);
        setMessage("Status: Unknown Error Occured, please try again later!")
        setMessageColor('text-red-400')
        throw error;
      }
    };
    const fileUploadAndUpdateLink = async () => {
      try {
        if (pdfFile instanceof File) {
          const renamedBlob = new Blob([pdfFile], { type: pdfFile.type });
          const renamedFile = new File([renamedBlob], `${timeLineUrl}-pdfFile.${pdfFile.name.split('.').pop()}`, { type: pdfFile.type });
          const { response } = await uploadFile(renamedFile, userDetails?.username);
          const pdfLink = `${MEDIA_URL}`+'media/'+userDetails?.username+'/'+renamedFile.name
          setMessage("Status: Saving... 55%")

          setPdfLink(pdfLink)
          setFile(pdfLink)
          return pdfLink;
        } else {
          console.warn('No PDF file provided, link detected returning link');
          return pdfFile;
        }
      } catch (error) {
        setMessage("Status: Unknown Error Occured, please try again later!")
        setMessageColor('text-red-400')
        console.error('Error uploading PDF file:', error);
        throw error;
      }
    };
    useEffect(() => {
      
    }, [pdfLink, updatedImageLinks]);
    
    useEffect(()=>{
      const fileUpload = async() =>{ 
        onEditTimeLine()
      }
      if (pdfFile instanceof File) {
        isLoading(true)
        fileUpload()
      }
    }, [pdfFile])
    
    
    
    const onEditTimeLine = async() =>{
     
      try {
        isLoading(true)
        setMessage("Status: Saving...")
        setLoaderMessage("Saving TimeLine, Please hold!")
        setMessageColor('text-orange-400')
        const [imageLinks, pdfLink, imageFile] = await Promise.all([uploadImagesAndUpdateLinks(), fileUploadAndUpdateLink(), onCreateElement()]);
        let customTimeLineUrl = timeLineUrl

        if(timeLineUrl!==titleDetails.url)
          customTimeLineUrl = titleDetails.url


        let TimeLineUrl = timeLineUrl
        if(timeLineUrl!==timeLineData?.timeLineUrl)
          TimeLineUrl = timeLineData?.timeLineUrl

        const timeLineDataToSend = {
          titleDetails: titleDetails,
          imageFiles: imageLinks,
          pdfLink: pdfLink,
          privacy: privacy, 
          buttons: buttons,
          leadCapture: leadCapture,
          settings: settings,
          controls: controls,
          analytics: analytics,
          automation: automation,
          elements: elements,
          themeStyle: themeStyle,
        }
        console.log("SENDING", timeLineDataToSend)
        setLoaderMessage("85% Saved!")
        setMessage("Status: Saving... 85%")
        const response = await editTimeLine({timeLineData: timeLineDataToSend, timeLineUrl: timeLineUrl, customTimeLineUrl: customTimeLineUrl});
        setMessage("Status: Saving... 100%")
        setLoaderMessage("Fully Saved!")
        setMessageColor('text-green-400')
        setTimeout(() => {
          setMessage('')
        }, 3000);
        
        isLoading(false)

        
      } catch (error) {
        console.error(error);
      }

    }

   

  
    
    useEffect(() =>{
    
      const replaceLocalUrlWithBaseUrl = (url) => {
        if (typeof url === 'string' && /^https?:\/\/\S+$/.test(url)) {
          if (url.includes('http://127.0.0.1:8000/')) {
            return url.replace('http://127.0.0.1:8000/', MEDIA_URL);
          }
          return url;
        }
        return '';
      };
    
      // Check and set logo image URL
      if (imageFiles?.logoImage instanceof File) {
        const reader = new FileReader();
        reader.onload = () => {
          setLogoUrl(reader.result);
        };
        reader.readAsDataURL(imageFiles?.logoImage);
      } else {
        setLogoUrl(replaceLocalUrlWithBaseUrl(imageFiles?.logoImage));
      }
    
      // Check and set background image URL
      if (imageFiles?.backgroundImage instanceof File) {
        const reader = new FileReader();
        reader.onload = () => {
          setBackgroundUrl(reader.result);
        };
        reader.readAsDataURL(imageFiles?.backgroundImage);
      } else {
        setBackgroundUrl(replaceLocalUrlWithBaseUrl(imageFiles?.backgroundImage));
      }
    
      // Check and set favicon image URL
      if (imageFiles?.faviconImage) {
        const faviconLink = document.querySelector('link[rel="icon"]');
        if (faviconLink) {
          faviconLink.href = replaceLocalUrlWithBaseUrl(imageFiles.faviconImage);
        } else {
          const newFaviconLink = document.createElement('link');
          newFaviconLink.rel = 'icon';
          newFaviconLink.href = replaceLocalUrlWithBaseUrl(imageFiles.faviconImage);
          document.head.appendChild(newFaviconLink);
        }
      }
      
    }, [imageFiles, setImageFiles])

    const[loaders, setLoaders] = useState({
      imageDownload: false,
      pdfDownload: false
    })
    
    

    const [showSideBar, setShowSidebar] = useState()
    const toggleSideBar = () =>{
      setShowSidebar(!showSideBar)
    }
    const [hideMenu, setHideMenu] = useState()
    const toggleMenu = () => {
      setHideMenu(!hideMenu)
    }
    return (
      <div className='w-full flex flex-row h-screen bg-gray-100 text-base overflow-auto '>
        <LeftBar newElementDetails={newElementDetails} newElementOpen={newElementOpen} onElementOpen={onElementOpen} elements={elements} setElements={setElements} setNewElementDetails={setNewElementDetails} createLoader={createLoader} createMessage={createMessage} onCreateElement={onCreateElement}/>
        <div className='w-full flex flex-col h-screen bg-gray-100 text-base overflow-auto '>
            <Header setFile={setFile} loaders={loaders} handleConvertToPdf={handleConvertToPdf} handleConvertToImage= {handleConvertToImage} toggleSidebar={toggleSideBar} pdfUrl={pdfFile} setSettings={setSettings} settings={settings} timeLineUrl={timeLineUrl}/>
            <div className='flex w-full overflow-hidden h-full '>
                <div ref={componentRef} className='viewPage flex-1 p-10 rounded-[1.15rem] h-[95%] ml-6 bg-white my-4 overflow-auto relative'>
                {loading?(
                  <div className='w-full h-full grid items-center'>
                    <Spinner message={timelineLoaderMessage}/>
                  </div>
                ):(
                  <>
                  {!pdfFile ? (
                   
                   
                   <>
                     <div className="absolute w-full h-full left-0 top-0">
                      {imageFiles?.backgroundColor && !imageFiles?.backgroundImage?(
                        <div className={`object-cover w-full h-full`} style={{'background-color': `${imageFiles.backgroundColor}`}}></div>
                      ):(
                        <>
                          {backgroundUrl&&<img src={backgroundUrl} alt="" className='object-cover w-full h-full'/>}
                        </>
                      )}
                     </div>
                    {buttons&&(
                       <div className='absolute right-2 top-3 flex flex-col z-10'>
                        {buttons?.button1 && buttons?.button1.active !== false &&(
                          
                          <a className='px-3 py-2 text-sm rounded-[10px] my-2 cursor-pointer' style={{
                            backgroundColor: buttons?.button1?.bt1Color,
                            color: buttons?.button1?.bt1TextColor
                          }}
                          href={buttons?.button1?.bt1Url}
                          target= '_blank'
                          >{buttons?.button1?.bt1Text}</a>
                        )}
                        {buttons.button2 && buttons?.button2.active !== false &&(
                          <a className='px-3 py-2 text-sm rounded-[10px] my-2 cursor-pointer' style={{
                            backgroundColor: buttons?.button2?.bt2Color,
                            color: buttons?.button2?.bt2TextColor
                          }}
                          href={buttons?.button2?.bt2Url}
                          target= '_blank'
                          >{buttons?.button2?.bt2Text}</a>
                        )}
                        
                      </div>
                    )}
                 
                 <div className='flex justify-center h-full relative '>
                  <p className={`absolute text-[16px] left-0 -top-6 flex font-bold`}  onMouseEnter={() => setHover(true)}onMouseLeave={() => setHover(false)} style={{color: `${titleDetails.color}`}}>{titleDetails.title}{titleDetails.description&&<div className='relative'>
                    <span class="material-symbols-outlined cursor-pointer relative text-[18px] mt-1" >priority_high</span>
                    {isHovered && (
                        <div className="absolute bg-gray-400 h-auto w-[150px] text-white text-[12px] px-5 rounded-[10px] z-50"><p>{titleDetails.description}</p></div>
                    )}
                    </div>}</p>
                    {logoUrl&&
                        <div className='text-[12px] fixed bottom-6 right-10 flex font-bold flex items-center justify-center max-w-max h-[50px]'>
                          <a href={imageFiles?.logoRedirect|| ''} className='w-full h-full' target='_blank'>
                            <img src={logoUrl} alt="logo-img" className='object-cover w-full h-full'/>
                          </a>
                        </div>
                      }
                  
                  {elements.length>0?(
                  
                     <TimeLineViewer pdfUrl={pdfFile} elements={elements} themeStyle={themeStyle} height={540} backgroundImage={imageFiles?.backgroundImage} settings={settings} controls = {controls} hideMenu={hideMenu} />
                 
                  ):(

                    <div className='w-full h-full grid grid-col-1 items-center text-center'>
                        <p className='font-bold text-[30px] text-[#1E78C8]'>Try Creating an Element!</p>
                        
                      </div>

                  )}
               
                 </div>
                
                  </>
                ):(
                  <>
                  {fetching ? (
                    <>
                  
                      
                      <div className='w-full h-full grid grid-col-1 items-center text-center'>
                        <p className='font-bold text-[30px] text-[#1E78C8]'>JUST A MOMENT!</p>
                        <Spinner message={'Loading...'}/>
                      </div>
                 
                  </>
                    
                  ):(
                    <>
                    
                      <div className='w-full h-full grid grid-col-1 items-center text-center'>
                        <p className='font-bold text-[30px] text-[#1E78C8]'>START BY IMPORTING A PDF!</p>
                      {/* <Spinner/> */}
                      </div>
                   
                     </>
                  )}
                  </>
                )}
                  </>
                )}
                </div>
                <Sidebar additionalClasses={''} themeStyle={themeStyle}  setThemeStyle={setThemeStyle} analytics={analytics} setAnalytics={setAnalytics} automation={automation} setAutomation={setAutomation} settings={settings} setSettings={setSettings} controls={controls} setControls= {setControls} showDiv={showSideBar} setTitleDetails={setTitleDetials} titleDetails={titleDetails} imageFiles={imageFiles} setImageFiles={setImageFiles} onCreate={onEditTimeLine} message={message} messageColor={messageColor} privacy={privacy} setPrivacy={setPrivacy} buttons={buttons} setButtons={setButtons} loading={loading} sLeadCapture={leadCapture} setSLeadCapture={setLeadCapture}/>
            </div>
            
        </div>
      </div>
    );
}

export default EditPage;