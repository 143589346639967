
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import EditPage from './pages/editPage';
import ViewPage from './pages/viewPage';
function App() {
  return (
    <div className="App">
     <Router>
            <Routes>
              <Route path="/" element={<EditPage/>} />
              <Route exact path="edit/*" element={<EditPage/>} />
              <Route exact path="view/*" element={<ViewPage/>} />
            </Routes>
    </Router>
    </div>
  );
}

export default App;
