import React from 'react';

const Language = ({onClose, leadCapture, setLeadCapture}) => {


    return (
        <div className='fixed top-1/2 left-1/2 h-auto transform -translate-x-1/2 -translate-y-1/2 z-50 flex flex-col bg-gray-100 w-[800px]  rounded-[10px] shadow-md items-center'>           
            <div className='my-3 w-full text-center'>
              <p className='text-base font-bold mr-8'>Language</p>
            </div>
            <div className='flex w-[90%] mx-4'>
                <div className=' flex flex-col w-1/3 ml-6'>
                      <div className='  w-full'>
                        <p className='text-base font-bold'>Password Page</p>
                      </div>
                          <div className=' w-full'>
                          <p className='text-sm'>Title</p>
                          <textarea type='text' placeholder='This is a private Timeline. To view it, please enter the password.' className='w-[80%] border border-gray-400 my-2 px-2 py-1 rounded-[10px] ' onChange={(e)=>setLeadCapture({...leadCapture, passwordTitle: e.target.value})} value={leadCapture?.passwordTitle}/>

                      </div>
                      <div className=' w-full'>
                          <p className='text-sm'>Password</p>
                          <input type='text' placeholder='Password' className='w-[80%] border border-gray-400 my-2 px-2 py-1 rounded-[10px] ' onChange={(e)=>setLeadCapture({...leadCapture, passwordText: e.target.value})} value={leadCapture?.passwordText}/>

                      </div>
                      <div className=' w-full'>
                          <p className='text-sm'>Submit Button</p>
                          <input type='text' placeholder='Submit' className='w-[80%] border border-gray-400 my-2 px-2 py-1 rounded-[10px] ' onChange={(e)=>setLeadCapture({...leadCapture, passwordSubmit: e.target.value})} value={leadCapture?.passwordSubmit}/>

                      </div>
          
                </div>
                <div className='flex flex-col w-1/3'>
               
                       <div className='  w-full'>
                         <p className='text-base font-bold'>Lead Capture Form</p>
                       </div>
                           <div className=' w-full'>
                           <p className='text-sm'>Email</p>
                           <input type='text' placeholder='any' className='w-[80%] border border-gray-400 my-2 px-2 py-1 rounded-[10px] ' onChange={(e)=>setLeadCapture({...leadCapture, email: {...leadCapture?.email, value: e.target.value}})} value={leadCapture?.email?.value}/>

                       </div>
                       <div className=' w-full'>
                           <p className='text-sm'>Name</p>
                           <input type='text' placeholder='any' className='w-[80%] border border-gray-400 my-2 px-2 py-1 rounded-[10px] ' onChange={(e)=>setLeadCapture({...leadCapture, name: {...leadCapture?.name, value: e.target.value}})} value={leadCapture?.name?.value}/>

                       </div>
                       <div className=' w-full'>
                           <p className='text-sm'>Phone</p>
                           <input type='text' placeholder='any' className='w-[80%] border border-gray-400 my-2 px-2 py-1 rounded-[10px] ' onChange={(e)=>setLeadCapture({...leadCapture, phone: {...leadCapture?.phone, value: e.target.value}})} value={leadCapture?.phone?.value}/>

                       </div>
                       <div className=' w-full'>
                           <p className='text-sm'>Company</p>
                           <input type='text' placeholder='any' className='w-[80%] border border-gray-400 my-2 px-2 py-1 rounded-[10px] ' onChange={(e)=>setLeadCapture({...leadCapture, company: {...leadCapture?.company, value: e.target.value}})} value={leadCapture?.company?.value}/>

                       </div>
                       <div className=' w-full'>
                           <p className='text-sm'>Submit</p>
                           <input type='text' placeholder='Submit' className='w-[80%] border border-gray-400 my-2 px-2 py-1 rounded-[10px] ' onChange={(e)=>setLeadCapture({...leadCapture, submit: {...leadCapture?.submit, value: e.target.value}})} value={leadCapture?.submit?.value}/>

                       </div>
                       <div className=' w-full'>
                           <p className='text-sm'>Skip</p>
                           <input type='text' placeholder='Skip' className='w-[80%] border border-gray-400 my-2 px-2 py-1 rounded-[10px] ' onChange={(e)=>setLeadCapture({...leadCapture, skip: {...leadCapture?.skip, value: e.target.value}})} value={leadCapture?.skip?.value}/>

                       </div>
                </div>
                <div className='flex flex-col w-1/3'>
               
                    <div className='  w-full'>
                      <p className='text-base font-bold'>Email Verification</p>
                    </div>
                        <div className=' w-full'>
                        <p className='text-sm'>Enter 4 digit OTP sent to you</p>
                        <input type='text' placeholder='Enter 4 digit OTP sent to you' className='w-[80%] border border-gray-400 my-2 px-2 py-1 rounded-[10px] ' onChange={(e)=>setLeadCapture({...leadCapture, emailVerficationTitle: e.target.value})} value={leadCapture?.emailVerficationTitle}/>

                    </div>
                    <div className=' w-full'>
                        <p className='text-sm'>Verify Button</p>
                        <input type='text' placeholder='Verify' className='w-[80%] border border-gray-400 my-2 px-2 py-1 rounded-[10px] ' onChange={(e)=>setLeadCapture({...leadCapture, verifyEmailButton: e.target.value})} value={leadCapture?.verifyEmailButton}/>

                    </div>
            
                </div>
            </div>
            <div className='flex w-[50%] my-4 h-10 text-sm'>
                <button className='font-bold w-[50%] bg-[#1E78C8] mx-2 text-white rounded-[10px] cursor-pointer border-2 border-[#1E78C8] hover:bg-white hover:text-[#1E78C8] ease-in-out duration-300' onClick={onClose}>OK</button>
                <button className='font-bold w-[50%] mx-2 rounded-[10px] bg-gray-400 text-white cursor-pointer border-2 border-gray-400 hover:bg-white hover:text-gray-600 ease-in-out duration-300' onClick={onClose}>Cancel</button>
            </div> 
        </div>
    );
}

export default Language;