import React, { useState } from 'react';

const EditContainer = ({onClose, title, description, time, elements, setElements, index}) => {

    const updatedElements = [...elements];

    const onSave = () =>{
      setElements(updatedElements);
      onClose()
    }

    return (
        <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 flex flex-col bg-gray-100 w-[500px] h-[580px] rounded-[10px] shadow-md items-center overflow-auto'>           
            <div className='mx-8 my-4 w-[80%] text-left'>
              <p className='text-base font-bold'>Edit Container</p>
            </div>
            <div className='flex flex-col mx-2 items-center'>
            <div className='mx-8 my-2 w-[80%]'>
                    <select className='bg-white border-2 border-gray-300 w-full p-2 rounded-[5px] text-gray-800' defaultValue={'None'}>
                      <option value={'None'}>Container</option>
                    </select>
            </div>
            <div className='mx-8 my-2 w-[80%]'>
              <div className='flex justify-between'>
                <label htmlFor="date">Time <span className='text-[#8392AB]'>(e.g 2016-2019)</span></label>
                <div>
                
                </div>
              </div>
              <input className="border-2 border-gray-200 w-full p-2 rounded-[10px] " defaultValue={time} name="time" onChange={(e)=> updatedElements[index].time =  e.target.value}/>
            </div>
            
            <div className='mx-8 my-2 w-[80%]'>
              <div className='flex justify-between'>
                <label htmlFor="title">Title <span className='text-[#8392AB]'>(max 200 characters)</span></label>
                <div>
                <input type="color" name="title-color" id="" className='bg-transparent rounded-md w-6' 
                
                value={undefined} onChange={(e)=> updatedElements[index].titleColor =  e.target.value}/>
                <input type="color" name="title-color" id="" className='bg-transparent rounded-md w-6' 
                
                value={undefined} onChange={(e)=> updatedElements[index].titleTextColor =  e.target.value}/>
                </div>
              </div>
              <input className="border-2 border-gray-200 w-full p-2 rounded-[10px] " defaultValue={title} name="title" onChange={(e)=>updatedElements[index].title =  e.target.value}/>
            </div>
           
            <div className='mx-8 my-2 w-[80%] text-left'>
              <label htmlFor="description">Description <span className='text-[#8392AB]'>(max 500 characters)</span></label>
              <textarea className="border-2 border-gray-200 w-full px-2 py-1 rounded-[10px]" name="description" rows={5} defaultValue={description} onChange={(e)=>updatedElements[index].description =  e.target.value}/>
            </div>
            
            </div>
            <div className='flex w-[80%] my-4 mb-6 h-10 text-sm'>
                <button className='font-bold w-[50%] mx-2 rounded-[10px] bg-gray-400 text-white cursor-pointer border-2 border-gray-400 hover:bg-white hover:text-gray-600 ease-in-out duration-300' onClick={onClose}>Cancel</button>
                <button className='font-bold w-[50%] bg-[#1E78C8] mx-2 text-white rounded-[10px] cursor-pointer border-2 border-[#1E78C8] hover:bg-white hover:text-[#1E78C8] ease-in-out duration-300' onClick={onSave}>Save</button>
            </div> 
        </div>
    );
}

export default EditContainer;